const hk={
    // 左侧菜单
    messages: {
        SSJK:'即時監控',
        AQJK:'安全監控',
        LSHF:'歷史重播',
        AQGL:'安全管理',
        TJBB:'統計報表',
        YYGL:'運營管理',
        GZGL:'規則管理',
    },
    //历史回放
    lshf:{
        FJ:'附近',
        KSRQ:'開始日期',
        JSRQ:'結束日期',
        SS:'蒐索',
        CPH:'選擇/輸入車牌',
        ACLXZ:'按車輛選擇',
        SDXZ:'手動選擇',
        WLGL:'圍欄管理',
        QYGL:'區域管理',
        XZFW:'選擇範圍',
        QRSS:'確認蒐索'
    },
    // 车辆信息页面
    CLXXpage:{
        SSCD:'所屬車隊',
        XZZT:"選擇狀態",
        XZGS:"選擇公司",
        AZRQXZ:"安裝日期選擇:",
        AZKSRQ:"安裝開始日期",
        AZJSRQ:"安裝結束日期",
        PPLX:"匹配類型",
        QSRMHCXNR:"請輸入模糊查詢內容",
        SS:"蒐索",
        SYCL:"所有車輛",
        TJCL:"匯出表格",
        DCBG:'导出表格',
        XGGS:"修改公司",
        CEDR:"從Excel導入",
        ZDYDC:"自定義匯出",
        CLid:"車輛ID",
        CPH:"車牌號",
        SIMK:"SIM卡",
        CLXH:"車輛型號",
        CPYS:"車牌顏色",
        SBH:"設備號",
        ZHSXSJ:"最後上線時間",
        SSGS:"企業名稱",
        CD:"車隊",
        JSYXM:"駕駛員姓名",
        GXSJ:"更新時間",
        CLXX:"車輛資訊",
        BJCL:"編輯車輛",
        YHSQ:"用戶授權",
        SCCL:"删除車輛",
        JBXX:"基本資訊",
        SBXX:"設備資訊",
        SBAZXX:"設備安裝資訊",
        ZJXX:"證件資訊",
        CLPZCS:"車輛配寘及主要技術參數",
        JYYS:"經營運輸資訊",
        GMDJ:"購買登記資訊",
        QRXG:'確認修改',
        CKCLXX:'查看 車輛資訊',
        GB:'關閉',
        BJCLXX:'編輯  車輛資訊',
        HYSQ:'用戶授權',
        SQ:'授權',
        SCCLXX:'删除 車輛資訊',
        S:'是',
        F:'否',
        QX:'取消',
        TJCLXX:'添加 車輛資訊',
        QDTJ:'確定添加',
        BC:'保存',
        CLYS:'車輛顏色',
        DLS:'代理商',
        QYBH:'企業編號',
        JC:'簡稱',
        FWDQR:'服務到期日',
        CLZL:'車輛種類',
        CLLX:'車輛類型',
        ZZCJ:'車輛廠牌號',
        CLYT:'車輛用途',
        BFRQ:'報廢年限',
        XSZ:'限速值',
        LCXS:'里程係數',
        CJH:'車架號',
        SJ:'司機',
        YYS:'運營商',
        FWKSR:'服務開始日',
        AZRQ:'安裝日期',
        CLPP:'車輛品牌',
        CLDJ:'車輛等級',
        SYZT:'營運狀態',
        NJRQ:'年檢日期',
        BLTS:'保留天數',
        CPHDL:'車牌號登入',
        QY:'啟用',
        JSMC:'角色名稱（分配許可權）',
        CLTU:'車輛圖標',
        BZ:'備註',
        SB:'設備',
        SIMKH:'SIM卡號',
        ZDLX:'終端類型',
        SBXLH:'設備序號',
        SIMKHSJ:'SIM卡號數據',
        SJKH:'實際卡號',
        TDCS:'通道參數',
        IOSRCS:'IO輸入參數',
        CGQCS:'感測器參數',
        SM:'數目',
        WSMK:'外設模塊',
        ZCSP:'支持視頻',
        ZCDJ:'支持對講',
        ZCJT:'支持監聽',
        TTSYYBF:'TTS（TTS語音播放）',
        ZYX:'主油箱',
        FYX:'副油箱',
        YLKZQ:'油路控制器（斷開油路和恢復油路）',
        DLKZQ:'電路控制器（斷開電路和恢復電路）',
        KLTJ:'客流統計',
        TYJCXT:'胎壓監測系統',
        CZCJJQ:'計程車計價器',
        ZLCGQ:'重量感測器',
        SWCGQ:'水位感測器',
        SSCGQ:'水深感測器',
        DZS:'電子鎖',
        AZQK:'安裝情况',
        TYJC:'胎壓監測',
        GJJSFZ:'高級駕駛輔助',
        JSZTKZ:'駕駛狀態監控',
        MDJC:'盲點監測',
        BDJCFZ:'變道決策輔助',
        YTJ:'一體機',
        TYJCPP:'胎壓監測品牌',
        TYJCXH:'胎壓監測型號',
        GJJSFZPP:'高級駕駛輔助品牌',
        GJJSFZXH:'高級駕駛輔助型號',
        JSZTJKPP:'駕駛狀態監控品牌',
        JSZTJKXH:'駕駛狀態監控型號',
        MDJCPP:'盲點監測品牌',
        MDJCXH:'盲點監測型號',
        BDJCFZPP:'變道決策輔助品牌',
        BDJCFZXH:'變道決策輔助型號',
        YTJPP:'一體機品牌',
        YTJXH:'一體機型號',
        SBAZZP:'設備安裝照片',
        SCXZTP:'上傳選擇圖片',
        AZR:'安裝人',
        AZRDH:'安裝人電話',
        XSZBH:'行駛證編號',
        YSZBH:'運輸證編號',
        XSZDQSJ:'行駛證到期時間',
        YSZDQSJ:'運輸證到期時間',
        XSZZP:'行駛證照片',
        XZSCTP:'選擇上傳圖片',
        DLYSZGZ:'道路運輸資格證',
        CLT:'車輛圖',
        CLDDZZP:'車輛登記證照片',
        ZFZZP:'身份證照片',
        DPXH:'底盤型號',
        WKCCC:'外廓尺寸長',
        WGCCK:'外廓尺寸寬',
        WKCCG:'外廓尺寸高',
        NKCCC:'內廓尺寸長',
        NKCCK:'內廓尺寸寬',
        NKCCG:'內廓尺寸高',
        ZZL:'總質量',
        ZBZL:'整備品質',
        ZQYZZL:'准牽引總質量',
        HDZZ:'核定載重(噸)',
        HDZRS:'核定載人數',
        FDJXH:'發動機型號',
        FDJH:'發動機號',
        PL:'排量',
        GL:'功率',
        PFBZ:'排放標準',
        RLZL:'燃料種類',
        DCLX:'電池類型',
        QDDJXH:'驅動電機型號',
        DJGL:'電機功率',
        DLLX:'動力類型',
        ZJ:'軸距',
        ZS:'軸數',
        LTS:'輪胎數/規格',
        XCZDFS:'行車制動管道',
        ZDQXS:'制動器形式',
        ZDFBSXT:'制動防抱死系統(ABS)',
        BSQXS:'變速器形式',
        HSQ:'緩速器',
        KTXT:'空調系統',
        YYYSXX:'經營運輸資訊',
        YYZH:'營運證號',
        BXLB:'企業經營許可證',
        XLLC:'線路里程',
        YYXL:'運營線路',
        DLYSXKZ:'道路運輸許可證',
        JYQX:'經營期限到期時間',
        JYFW:'經營範圍',
        JYXZ:'經營性質',
        DY:'地域',
        HFJG:'核發機關',
        YSHYLB:'運輸行業類別',
        YSCLX:'運輸車輛類型',
        CJD:'車籍地',
        HWDS:'貨物噸數/始發地',
        HWPM:'貨物品名/訖發地',
        YSCFD:'運輸出發地/始發站',
        YSMDD:'運輸目的地',
        HWTJ:'貨物體積（立方）',
        GMDJXX:'購買登記資訊',
        BH:'編號',
        JDCSYR:'機動車所有人',
        DJRQ:'登記日期',
        DJZBH:'登記證編號',
        LXR:'連絡人',
        LLDH:'聯繫電話',
        CLLY:'車輛來源',
        MBLC:'碼表里程',
        ZRSJ:'轉入時間',
        ZCSJ:'轉出時間',
        CLGMRQ:'車輛購買日期',
        CLCCRQ:'車輛出廠日期',
        CJ:'車價',
        FPHM:'發票號碼',
        GCFP:'購車發票',
        FR:'法人',
        FRDZ:'法人地址',
        FRLXDH:'法人聯繫電話',
    },
    //司机信息页面
    SjXXpage:{
        SYSJ: '所有司機', 
        TJSJ: '添加司機',
        YEXCDR: '由Excel導入', 
        DCZEXC: '匯出至Excel',
        PLSC: '批量删除', 
        XZLX: '選擇類型',
        MHCX: '模糊查詢', 
        JSID: '司機ID',
        SJXM: '姓名', 
        SJXB: '性别',
        LXFS: '聯繫方式', 
        SFZHM: '身份證號碼', 
        SSGS: '所屬公司',
        JSZLX: '駕駛證類型', 
        JSZKSSJ: '駕駛證有效期開始時間',
        JSZJZSJ: '駕駛證有效期截止時間', 
        JSZH: '駕駛證號', 
        ZGZBH: '資格證編號',

        YZGZHM: '原資格證編號',
        ZT: '狀態',
        ZGZT: '在崗狀態',
        SJLX: '司機類型',
        SJXJ: '司機星級',
        GJ: '國籍',
        ZGZZXZT: '資格證註銷狀態',
        FWZGZHM:'服務資格證號碼',

        SJXX: '司機資訊', 
        BJSJ: '編輯司機',
        SCSJ: '删除司機', 
        JBXX: '基本資訊',
        CPH: '車牌號',
        CSRQ: '出生日期', 
        JG: '籍貫', 
        MZ: '民族',
        DZ: '地址', 
        DY: '地域',
        WHCD: '教育程度', 
        AQJY: '安全教育',
        YWC: '已完成',
        WWC: '未完成', 
        SFZZP: '身份證照片',
        SJRX: '司機人像', 
        ZJZ: '證件照',
        ZWTP: '指紋圖片', 
        ZJXX: '證件資訊',
        QDTJ: '確定添加', 
        GB: '關閉',  
        ZGZZT: '資格證狀態', 
        ZGZLX: '資格證類別',
        ZGZTP: '資格證圖片', 
        DLYSZGZ: '道路運輸資格證', 
        DLYSZGZZP: '道路運輸資格證照片',
        ZSZZP: '駕駛證照片', 
        JZLX: '駕駛證類別',
        JSZBZRQ: '駕駛證辦證日期', 
        JSZYXQKSSJ: '駕駛證有效期開始時間', 
        JSZYXQJZSJ: '駕駛證有效期截止時間',
        TQTXTS: '提前提醒天數', 
        YYZZP: '營運證照片', 
        CLXX: '車輛資訊',
        JDKH: '監督卡號', 
        JDKYXQ: '監督卡有效期', 
        YXQ: '有效期',
        JDKZT: '監督卡狀態', 
        HFJG: '核發機關', 
        WYCJSZH: '網約車駕駛證號',
        WYCJSZTP: '網約車駕駛證圖片', 
        WYCKSSJ: '網約車駕駛證有效期開始時間', 
        WYCJSSJ: '網約車駕駛證有效期結束時間',
        YXQKSSJ: '有效期開始時間', 
        YXQJSSJ: '有效期結束時間', 
        SKXX: '收款資訊', 
        SJYHKH: '司機銀行卡號', 
        ZFBSKM: '支付寶收款碼',
        WXSKM: '微信收款碼', 
        YZFEWM: '翼支付二維碼', 
        QR: '確認', 
        QRBJ: '確認編輯',
        SFSCSJ: '是否將該司機資訊删除？', 
        S: '是', 
        F: '否',
        JZSJ: '截止時間',
        KSJS:'開始時間', 
        QX: '取消',
        CKSJXX:'查看司機資訊',
        SCSJXX:'删除司機資訊',
        BJSJXX:'編輯司機資訊',
        TJSJXX:'添加司機資訊'
    },
     // 公司页面信息
     company:{
        QYJC:'企業簡稱',
        QYJYZT:'企業經營狀態',
        JJLX:'經濟類型',
        JYXKZ:'經營許可證',
        JYFW:'經營範圍',

        SYGS:'所有公司',
        XGGS:'修改查崗',
        TJGS:'添加公司',
        DCZEXC:'匯出至Excel',
        CEXCDR:'從Excel導入',
        GSID:'公司ID',
        GSMC:'公司名稱',
        DLMM:'登入密碼',
        QDMM:'確定密碼',
        SJXZQY:'上級行政區域',
        SSHY:'所屬行業',
        PPLX:'匹配類型',
        MHSS:'模糊搜索',
        ZZH:'主帳號',
        LXR:'連絡人',
        LXDH:'聯繫電話',
        DY:'地域',
        HFJG:'核發機關',
        FRLXDH:'法人聯繫電話',
        FRDZ:'法人地址',
        GSXQ:'公司詳情',
        BJGS:'編輯公司',
        BTSZ:'標題設定',
        AAAA:'删除公司',
        QRBJ: '確認編輯',
        QR: '確認',
        S: '是', 
        F: '否',
        QX: '取消',
        SFSCGS: '是否將該公司資訊删除？',
        CKGSWXX:'查看公司資訊',
        SCGSWXX:'删除公司資訊',
        BJGSWXX:'編輯公司資訊',
        TJGSWXX:'添加公司資訊',
        JBXX:'基本資訊',
        YXQ:'有效期',
        DLYSXJZ:'道路運輸許可證	',
        SJGS:'上級公司',
        FR:'法人',
        LXYX:'聯繫郵箱',
        JKY:'監控員',
        JKFZR:'監控負責人',
        YYS:'運營商',
        JKFZRLXFS:'負責人聯繫方式',
        CLTJ:'車輛添加/删除許可權',
        CGHFZH:'查崗回復帳號',
        GBBM:'企業編號',
        CGZDHF:'查崗自動回復',
        QY:'啟用',
        XTXX:'選填資訊',
        YYZZH:'營業執照號',
        ZCJZ:'註冊資金',
        DZ:'地址',
        JJ:'簡介（公司營業範圍）',
        WZLJ:'網站連結',
        BZ:'備註',
        GB:'關閉',
        QDTJ: '確定添加', 
        NRBNWK:'內容不能為空',
        MR:'默認',
        CDU:'長度為4-16位數',
        MMBYZ:'密碼不一致',
    }
    
}
export default hk