import { createStore } from "vuex";
import tab from "./modules/tab";
import getters from './getters'
import permission from './modules/permission'
export default createStore({
    state: {
        RegionDrop:[],
        historyMapSwitch:{
            'play':false,
            'suspend':false,
            'resetting':false,
        },
        historyMapData:null,
        // treeData:[],
		routes:[],
		addRoutes:[],
		menuRouters:[],
        options:null,
        graphData:null,
        graphData1:null,
        passengerPhone:'',
        homeClass:'1',
		tabData: '',
        carConfig:{
            'shengyu':'',
            'shiyu':'',
            'isuxintiao':'',
            'tcpyingdachaoshi':'',
            'tcpchongchuancishu':'',
            'smsyingdachaoshi':'',
            'smschongchuancishu':'',
            'jijiaqiyingyuncishuLimit':'',
            'qiyexukezheng':'',
            'jijiaqiyingyunTimeLimit':'',
            'qiyejiancheng':'',
            'danweidaima':'',
            'jijiaqiyuanchengsuoding':'',
            'zhongduanyingyunshijian':'',
            'isuluyinModel':'',
            'isuluyinTime':'',
            'lcdxintiaoTimeSpan':'',
            'ledxintiaoTimeSpan':'',
            'accoffSleepTime':'',
            'jijiaqishebeihao':'',
            'jingyingxukezheng':'',
            'qiandaomoshiSetting':'',
            'renlianshibie':'',
            'isuPhonejietingcelve':'',
            'longPhoneTime':'',
            'longPhoneTimeThisMonth':'',
            'phoneduanhaoLength':'',
            'jiantingPhone':'',
            'isuweihuPassword':'',
            'isuSound':'',
            'onCarVoice':'',
            'payVoice':'',
            'payFinishVoice':'',
            'offCarVoice':'',
            'liangdu':'',
            'duibidu':'',
            'baohedu':'',
            'sedu':'',
            'jiankongzhongxinPhone':'',
            'fuweiPhone':'',
            'huifuchuchangPhone':'',
            'jiankongsmsPhone':'',
            'jieshouisusmsPhone':'',
            'maxSpeed':'',
            'chaosuTime':'',
            'lianxujiashiTime':'',
            'minSleepTime':'',
            'maxStopTime':'',
            'dangtianjiashiTime':'',
            'chelianglichengdubiaoshu':'',
            'weizhihuibaocelve':'',
            'weizhihuibaofangan':'',
            'noLoginTimeSpan':'',
            'accOffTimeSpan':'',
            'accOnTimeSpan':'',
            'offCarTimeSpan':'',
            'onCarTimeSpan':'',
            'sleepTimeSpan':'',
            'alarmTimeSpan':'',
            'noLoginRangeSpan':'',
            'accOffRangeSpan':'',
            'accOnRangeSpan':'',
            'offCarRangeSpan':'',
            'onCarRangeSpan':'',
            'sleepRangeSpan':'',
            'alarmRangeSpan':'',
            'guaijiaobuchuanjiaodu':'',
            'zhufuwuApn':'',
            'zhufuwuUser':'',
            'zhufuwuPassword':'',
            'zhufuwuIp':'',
            'beifenfuwuApn':'',
            'beifenfuwuUser':'',
            'beifenfuwuPassword':'',
            'beifenfuwuIp':'',
            'zhufuwuTcp':'',
            'beifenfuwuTcp':'',
            'yikatongzhufuwuIp':'',
            'yikatongzhufuwuTcp':'',
            'yikatongbeifenfuwuIp':'',
            'yikatongbeifenfuwuTcp':'',
            'alarmpingbizi':'',
            'alarmsmsText':'',
            'alarmpaizhaokaiguan':'',
            'alarmcunchubiaozhi':'',
        },
        playMsg:null,
        videoData:{
            "cameraNoList": '',
            "carNum": "",
            "endTime": "",
            "startTime": "",
            'pageno':'',
            'pagesize':''
        },
        addusdata:{
            'username':'',
            "password":"000000",
            "ispassword":"000000",
            "remarks":"",
            "role":"",
            "business":"",
            'phone':''
        },
        lookusdata:null,
        editusdata:{
            'username':'',
            "password":"",
            "ispassword":"",
            "remarks":"",
            "role":"",
            "business":"",
            'phone':''
        },
        lookReledata:null,
        addReledata:{
            'roleName':'',
            "areaManagement":"",
            "callingManagement":"",
            "operationReport":"",
            "permissionLevel":"",
            "ruleManagement":"",
            "safetyManagement":"",
            "userManagement":"",
        },
        editReledata:{
            'id':'',
            'roleName':'',
            "areaManagement":"",
            "callingManagement":"",
            "operationReport":"",
            "permissionLevel":"",
            "ruleManagement":"",
            "safetyManagement":"",
            "userManagement":"",
        },
        videoIsubiaoshi:'',
        videoPage:1,
        videoSize:10,
        videoTotal:0,
        carNumMsg:null,
        SelectedDta:[],
        controlmenu:false,
        SelectedCarNum:[],
        primaryCarNum:'',
        ArrayOrder:[],
        mapPlace:{'baiduX':108.55, 'baiduY':34.32,'scale':5.6},
        realwindow:null,
        isPop:null,
        isTongDao:false,
        isTongDaoData:null,
        isDriverDatachange:'',
        isCarDatachange:'',
        treeData:[{"id": "2773ab70-6248-4416-9bb6-d9f0ec8883de","label": "监控中心","status": 0,"children": []}],//车辆树数据
        VideoLoading:false,
        downloadPace:'',
        usertoken:'',
        VideoCopHUrl:'',
        videoList:[],//历史视频列表
        closeRealTime:'',//实时监控监视
        videoPass:[],//视频通道集合
        videoPassone:null,//视频通道一
        videoPasstwo:null,//视频通道二
        videoPassthree:null,//视频通道三
        videoPassfour:null,//视频通道四
        govideo:'first',//切换到视频页面
        messageControl:{},//地图信息窗口控制
        RegionCarData:[],//区域查车统计数据
        RegionCountData:[],//区域查车车辆数据
        RegionData:[],//区域查车显示区域坐标组
        displayRegion:[],//区域管理显示区域坐标组
        addregion:[],//区域管理新增区域坐标组
        historyVideoData:{},//历史视频内容
        DriverDialogswitch:false,//司机信息开关
        CarDialogswitch:false,//车辆信息开关
        name: '',
        seatMonitor:'',//位置监控信息
        username:'',
        // 控制历史回放三个视口切换的开关
        title: '轨迹回放',
        //图片开关
        imgsSwitch:false,
        //图片地址
        dialogImageUrl:'',
        //字体大小
        font:'',
        //实时监控目标
        monitorTarget:[],
        // 区域查车车牌号
        locationCarNumber:'',
        // 区域开始时间
        startTime:'',
        // 区域结束时间
        endTime:'',
         // 区域查车范围
        range:'',

        // 详情信息页面数据
        detailsData: {

        },
        // 复制页面数据
        copyData: {},
        // 添加车辆页面数据
        addCarData: {
            audioType:'',//音屏格式
            dealRules:'',//协议
            fleet:'',//所属车队
            enterpriseNum:'',//企业编号
            abs: "",//制动防抱死系统（ABS）
            agent: "",//代理商
            airconditionSystem: "",//空调系统
            announceTime: "",//报废日期
            approvedLoad: "",//核定载重
            approvedPerson: "",//核定载人数
            baseplate: "",//底盘型号
            billNum: "",//发票号码
            billPhoto: "",//购车发票照片
            brakeType: "",//制动器形式
            brakingMode: "",//行车制动方式
            business: "",//所属公司
            carBrand: "",//车辆品牌
            carBuyTime: "",//车辆购买日期
            carColour: "",//车辆颜色
            carFactoryTime: "",//车辆出厂日期
            carIcon: "",//车辆图标
            carLevel: "",//车辆等级
            carModel: "",//车辆类型
            carModelii: "",//
            carNum: "",//车牌号
            carPhoto: "",//车辆图
            carPrice: "",//车价
            carRegisterPhoto: "",//车辆登记证照片
            carRegistration: "",//车籍地
            carSource: "",//车辆来源
            carType: "",//车辆型号
            carUse: "",//车辆用途
            carnumColour: "",//车牌颜色
            cellType: "",//电池类型
            circuirController: "",//电路控制器
            commodity: "",//货物品名/讫发地
            coutourHeight: "",//内廓尺寸高
            coutourLong: "",//内廓尺寸长
            coutourWidth: "",//内廓尺寸宽
            depature: "",//运输出发地/始发站
            depth: "",//水深传感器
            destination: "",//运输目的地
            dirverLicenseExpirationDate: "",//行驶证到期时间
            dirverLicensePhoto: "",//行驶证照片
            displacement: "",//排量
            driver: [],//司机
            driverAssistBrand: "",//高级驾驶辅助品牌
            driverAssistType: "",//高级辅助驾驶型号
            driverLicense: "",//行驶证编号
            driverStatusBrand: "",//驾驶状态监控品牌
            driverStatusType: "",//驾驶状态监控型号
            effluent: "",//排放标准
            engineNum: "",//发动机号
            engineType: "",//发动机型号
            equipmentSerial: "",//设备序列号
            equipmentType: "",//终端类型
            factory: "",//制造厂家
            fitTank: "",//副油箱
            fuelType: "",//燃料种类
            gpsContinuteTime: "",//gps保留天数
            id: "",//车辆id
            idcardPhoto: "",//身份证照片
            imacBrand: "",//一体机品牌
            imacType: "",//一体机型号
            inspectTime: "",//年检日期
            installPerson: "",//安装人
            installPhoto: "",//安装照片
            installTime: "",//安装日期
            installlPhone: "",//安装电话
            intercom: "",//支持对讲
            ioNum: "",//io输入参数
            issued: "",//核发机关
            isubiaoshi: "",//设备号
            jurdicalAddress: "",//法人地址
            jurdicalPerson: "",//法人
            jurdicalPhone: "",//法人联系电话
            langChangeAssistBrand: "",//变道决策辅助品牌
            langChangeAssistType: "",//变道决策辅助型号
            liasion: "",//联系人
            liasionPhone: "",//联系电话
            lineMileage: "",//线路里程
            lineType: "",//班线类别
            lock: "",//
            mainTank: "",//主油箱
            measurement: "",//货物体积（立方）
            mileageRatio: "",//里程系数
            monitor: "",//支持监听
            motorOwner: "",//机动车所有人
            motorPower: "",//电机功率
            motorType: "",//驱动电机型号
            num: "",//编号
            obd: "",//OBD
            operator: "",//运营商
            passNum: "",//通道参数个数
            passenger: "",//客流统计
            power: "",//功率
            powerType: "",//动力类型
            profileHeight: "",//外廓尺寸高
            profileLong: "",//外廓尺寸长
            profileWidth: "",//外廓尺寸宽
            quality: "",//整体质量
            rateLimitNum: "",//限速值
            realityNum: "",//计价器号
            region: "",//地域
            registrationNum: "",//登记证编号
            registrationTime: "",//登记日期
            remarks: "",//备注
            retarder: "",//缓速器
            roadTransportLicense: "",//道路运输许可证
            roleName: "",//角色名称（分配权限）
            rollTime: "",//转出时间
            scotomaBrand: "",//盲点检测品牌
            scotomaType: "",//盲点检测型号
            sensorNum: "",//传感器参数个数
            serviceCarType: "",//运输车辆类型
            serviceDueTime: "",//服务到期时间
            serviceNum: "",//营运证号
            serviceQuality: "",//经营性质
            serviceRoute: "",//运营路线
            serviceScope: "",//经营范围
            serviceStartTime: "",//服务开始日期
            serviceTime: "",//经营期限到期时间
            serviceTradeType: "",//运输行业类别
            shiftTime: "",//转入时间
            shippingLicense: "",//运输证编号
            shippingLicenseExpirationDate: "",//运输证到期时间
            shippingLicensePhoto: "",//运输证照片
            shortname: "",//简称
            simNum: "",//SIM卡号
            speedChangerType: "",//变速器形式
            stage: "",//水位传感器
            stopwatchMileage: "",//码表里程
            tankController: "",//油路控制器
            taximeter: "",//出租车计价器
            tonnage: "",//货物吨数/始发地
            totalWeight: "",//总质量
            towWeight: "",//准牵引总质量
            tts: "",//tts
            tyre: "",//胎压监控系统
            tyreBrand: "",//胎压检测品牌
            tyreNum: "",//轮胎数
            tyreType: "",//胎压检测型号
            useStatus: "",//使用状态
            video: "",//支持视频
            vin: "",//车架号
            weight: "",//重量传感器
            wheelBase: "",//轴距
            wheelNum: ""//轴数
        },
        // 编辑车辆页面数据
        editCarData: {
            audioType:'',//音频格式
            dealRules:'',//协议
            fleet:'',//所属车队
            enterpriseNum:'',//企业编号
            abs: "",                    //制动防抱死系统（ABS）
            agent: "",                  //代理商
            airconditionSystem: "",     //空调系统
            announceTime: "",           //报废日期
            approvedLoad: "",           //核定载重
            approvedPerson: "",         //核定载人数
            baseplate: "",              //底盘型号
            billNum: "",                //发票号码
            billPhoto: "",              //购车发票照片
            brakeType: "",              //制动器形式
            brakingMode: "",            //行车制动方式
            business: "",               //所属公司
            carBrand: "",               //车辆品牌
            carBuyTime: "",             //车辆购买日期
            carColour: "",              //车辆颜色
            carFactoryTime: "",         //车辆出厂日期
            carIcon: "",                //车辆图标
            carLevel: "",               //车辆等级
            carModel: "",               //车辆类型
            carModelii: "",             //车辆类型2
            carNum: "",                 //车牌号
            carPhoto: "",               //车辆图
            carPrice: "",               //车价
            carRegisterPhoto: "",       //车辆登记证照片
            carRegistration: "",        //车籍地
            carSource: "",              //车辆来源
            carType: "",                //车辆型号
            carUse: "",                 //车辆用途
            carnumColour: "",           //车牌颜色
            cellType: "",               //电池类型
            circuirController: "",      //电路控制器
            commodity: "",              //货物品名/讫发地
            coutourHeight: "",          //内廓尺寸高
            coutourLong: "",            //内廓尺寸长
            coutourWidth: "",           //内廓尺寸宽
            depature: "",               //运输出发地/始发站
            depth: "",                  //水深传感器
            destination: "",            //运输目的地
            dirverLicenseExpirationDate: "",//行驶证到期时间
            dirverLicensePhoto: "",     //行驶证照片
            displacement: "",           //排量
            driver: [],                 //司机
            driverAssistBrand: "",      //高级驾驶辅助品牌
            driverAssistType: "",       //高级辅助驾驶型号
            driverLicense: "",          //行驶证编号
            driverStatusBrand: "",      //驾驶状态监控品牌
            driverStatusType: "",       //驾驶状态监控型号
            effluent: "",               //排放标准
            engineNum: "",              //发动机号
            engineType: "",             //发动机型号
            equipmentSerial: "",        //设备序列号
            equipmentType: "",          //终端类型
            factory: "",                //制造厂家
            fitTank: "",                //副油箱
            fuelType: "",               //燃料种类
            gpsContinuteTime: "",       //gps保留天数
            id: "",                     //车辆id
            idcardPhoto: "",            //身份证照片
            imacBrand: "",              //一体机品牌
            imacType: "",               //一体机型号
            inspectTime: "",            //年检日期
            installPerson: "",          //安装人
            installPhoto: "",           //安装照片
            installTime: "",            //安装日期
            installlPhone: "",          //安装电话
            intercom: "",               //支持对讲
            ioNum: "",                  //io输入参数
            issued: "",                 //核发机关
            isubiaoshi: "",             //设备号
            jurdicalAddress: "",        //法人地址
            jurdicalPerson: "",         //法人
            jurdicalPhone: "",          //法人联系电话
            langChangeAssistBrand: "",  //变道决策辅助品牌
            langChangeAssistType: "",   //变道决策辅助型号
            liasion: "",                //联系人
            liasionPhone: "",           //联系电话
            lineMileage: "",            //线路里程
            lineType: "",               //班线类别
            lock: "",//
            mainTank: "",               //主油箱
            measurement: "",            //货物体积（立方）
            mileageRatio: "",           //里程系数
            monitor: "",                //支持监听
            motorOwner: "",             //机动车所有人
            motorPower: "",             //电机功率
            motorType: "",              //驱动电机型号
            num: "",                    //编号
            obd: "",                    //OBD
            operator: "",               //运营商
            passNum: "",                //通道参数个数
            passenger: "",              //客流统计
            power: "",                  //功率
            powerType: "",              //动力类型
            profileHeight: "",          //外廓尺寸高
            profileLong: "",            //外廓尺寸长
            profileWidth: "",           //外廓尺寸宽
            quality: "",                //整体质量
            rateLimitNum: "",           //限速值
            realityNum: "",             //计价器号
            region: "",                 //地域
            registrationNum: "",        //登记证编号
            registrationTime: "",       //登记日期
            remarks: "",                //备注
            retarder: "",               //缓速器
            roadTransportLicense: "",   //道路运输许可证
            roleName: "",               //角色名称（分配权限）
            rollTime: "",               //转出时间
            scotomaBrand: "",           //盲点检测品牌
            scotomaType: "",            //盲点检测型号
            sensorNum: "",              //传感器参数个数
            serviceCarType: "",         //运输车辆类型
            serviceDueTime: "",         //服务到期时间
            serviceNum: "",             //营运证号
            serviceQuality: "",         //经营性质
            serviceRoute: "",           //运营路线
            serviceScope: "",           //经营范围
            serviceStartTime: "",       //服务开始日期
            serviceTime: "",            //经营期限到期时间
            serviceTradeType: "",       //运输行业类别
            shiftTime: "",              //转入时间
            shippingLicense: "",        //运输证编号
            shippingLicenseExpirationDate: "",//运输证到期时间
            shippingLicensePhoto: "",   //运输证照片
            shortname: "",              //简称
            simNum: "",                 //SIM卡号
            speedChangerType: "",       //变速器形式
            stage: "",                  //水位传感器
            stopwatchMileage: "",       //码表里程
            tankController: "",         //油路控制器
            taximeter: "",              //出租车计价器
            tonnage: "",                //货物吨数/始发地
            totalWeight: "",            //总质量
            towWeight: "",              //准牵引总质量
            tts: "",                    //tts
            tyre: "",                   //胎压监控系统
            tyreBrand: "",              //胎压检测品牌
            tyreNum: "",                //轮胎数
            tyreType: "",               //胎压检测型号
            useStatus: "",              //使用状态
            video: "",                  //支持视频
            vin: "",                    //车架号
            weight: "",                 //重量传感器
            wheelBase: "",              //轴距
            wheelNum: ""                //轴数
        },
        //历史回放页面数据
        trajectoryData:[],
        CheckDate: '',                 //选中的日期
        load:'',
        doubleClickCoordinate:{},
        locusDtaa:{
            'carNum':'',
            'startTime':'',
            'endTime':''
        },                //选中的车牌
        driverDataImg:'',
        driverDataImgSwitch:false,
        // 添加司机信息
        driverData: {
            workLicenseImg:'',//资格证图片
            certificateStatus: "",          //资格证状态
            oldCertificate: "",             //原资格证编号
            dirverStatus: "",               //状态
            ondutyStatus: "",               //在岗状态
            adirverType: "",                //司机类型
            adirverStar: "",                //司机星级
            nationality: "",                //国籍
            certificateCancelStatus: "",    //资格证注销状态
            serviceCertificate: "",         //服务资格证号码
            address: "",                //地址
            area: "",                   //地域
            birthday: "",               //出生日期
            business: "",               //所属公司
            certificate: "",            //资格证编码
            dirverLicenseImg: "",       //驾驶证照片
            driverBankNum: "",          //司机银行卡号
            driverLicense: "",          //驾驶证号
            driverLicenseDate: "",      //驾驶证办证日期
            driverName: "",             //姓名
            educationLevel: "",         //文化程度
            fingerMarkImg: "",          //指纹图片
            id: "",                     //司机ID
            issued: "",                 //核发机关
            licenseEndtime: "",         //驾驶证有效期结束时间
            licenseImg: '',             //证件照
            licenseStarttime: "",       //驾驶证有效期开始时间
            licenseType: "",            //驾照类型
            nativePlace: "",            //籍贯
            nominal: "",                //民族
            onlineCarEndtime: "",       //网约车驾驶证有效期结束时间
            onlineCarLicense: "",       //网约车驾驶证号
            onlineCarLicenseImg: "",    //网约车驾驶证图片
            onlineCarStarttime: "",     //网约车驾驶证有效期开始时间
            operatorImg: "",            //营运证照片
            personCardImg: '',          //身份证照片
            personid: "",               //身份证号
            phone: "",                  //联系方式
            protraitImg: '',            //人像库
            remindDay: "",              //提前提醒天数
            roadLicense: "",            //道路运输资格证
            roadLicenseImg: "",         //道路运输资格证照片
            securityEducate: "",        //是否完成安全教育
            sex: "",                    //性别
            superviseNum: "",           //监督卡号
            superviseStatus: "",        //监督卡状态
            superviseValidity: "",      //监督卡有效期
            weixinImg: "",              //微信收款码
            workLicenseStatus: "",      //从业资格证状态
            workLicenseType: "",        //从业资格证类型
            yizhifuImg: "",             //翼支付收款码
            zhifubaoImg: ""             //支付宝收款码
        },
        // 查看、编辑司机信息
        seeDriverData: {
            certificateStatus: "",          //资格证状态
            oldCertificate: "",             //原资格证编号
            dirverStatus: "",               //状态
            ondutyStatus: "",               //在岗状态
            adirverType: "",                //司机类型
            adirverStar: "",                //司机星级
            nationality: "",                //国籍
            certificateCancelStatus: "",    //资格证注销状态
            serviceCertificate: "",         //服务资格证号码
            address: "",                //地址
            area: "",                   //地域
            birthday: "",               //出生日期
            business: "",               //所属公司
            certificate: "",            //资格证编码
            dirverLicenseImg: "",       //驾驶证照片
            driverBankNum: "",          //司机银行卡号
            driverLicense: "",          //驾驶证号
            driverLicenseDate: "",      //驾驶证办证日期
            driverName: "",             //姓名
            educationLevel: "",         //文化程度
            fingerMarkImg: "",          //指纹图片
            id: "",                     //司机ID
            issued: "",                 //核发机关
            licenseEndtime: "",         //驾驶证有效期结束时间
            licenseImg: "",             //证件照
            licenseStarttime: "",       //驾驶证有效期开始时间
            licenseType: "",            //驾照类型
            nativePlace: "",            //籍贯
            nominal: "",                //民族
            onlineCarEndtime: "",       //网约车驾驶证有效期结束时间
            onlineCarLicense: "",       //网约车驾驶证号
            onlineCarLicenseImg: "",    //网约车驾驶证图片
            onlineCarStarttime: "",     //网约车驾驶证有效期开始时间
            operatorImg: "",            //营运证照片
            personCardImg: '',          //身份证照片
            personid: "",               //身份证号
            phone: "",                  //联系方式
            protraitImg: "",            //人像库
            remindDay: "",              //提前提醒天数
            roadLicense: "",            //道路运输资格证
            roadLicenseImg: "",         //道路运输资格证照片
            securityEducate: "",        //是否完成安全教育
            sex: "",                    //性别
            superviseNum: "",           //监督卡号
            superviseStatus: "",        //监督卡状态
            superviseValidity: "",      //监督卡有效期
            weixinImg: "",              //微信收款码
            workLicenseStatus: "",      //从业资格证状态
            workLicenseType: "",        //从业资格证类型
            yizhifuImg: "",             //翼支付收款码
            zhifubaoImg: ""             //支付宝收款码
        },
// 查看，编辑公司信息
        seeCompanyData: {
            parentArea: "",//上级行政区域
            businessShortName: "",      //企业简称
            businessStatus: "",         //企业经营状态
            economy: "",                //经济类型
            operationLicense: "",       //经营许可证
            operationSphere: "",//经营范围
            address:'',                 //地址
            area:'',                    //地域
            autoReply:'',               //查岗自动回复
            autoReplyAdmin:'',          //查岗回复账号
            business:'',                //公司名称
            businessLicense:'',         //营业执照号
            carPermission:'',           //车辆添加/删除权限
            id:'',                      //公司ID
            introduction:'',            //简介
            isbn:'',                    //国际编码
            issued:'',                  //核发机关
            jurdicalAddress:'',         //法人地址
            jurdicalPerson:'',          //法人
            jurdicalPhone:'',           //法人联系电话
            liaisonEmail:'',            //联系邮箱
            liaisonPerson:'',           //联系人
            liaisonPhone:'',            //联系电话
            loadLicence:'',             //道路运输许可证
            masterAccount:'',           //主账户
            minitorLeader:'',           //监控负责人
            minitorLeaderPhone:'',      //监控负责人联系方式
            minitorPerson:'',           //监控员
            operator:'',                //运营商
            parentBusiness:'',          //上级公司
            registerFund:'',            //注册资金
            remark:'',                  //备注
            secret:'',                  //密码
            trade:'',                   //所属行业
            validity:'',                //有效期
            websiteLink:'',             //网站链接
        },
        //添加公司信息
        addCompanyData: {
            parentArea: "",//上级行政区域
            usinessShortName: "",       //企业简称
            businessStatus: "",         //企业经营状态
            economy: "",                //经济类型
            operationLicense: "",       //经营许可证
            operationSphere: "",        //经营范围
            address:'',                 //地址
            area:'',                    //地域
            autoReply:'',               //查岗自动回复
            autoReplyAdmin:'',          //查岗回复账号
            business:'',                //公司名称
            businessLicense:'',         //营业执照号
            carPermission:'',           //车辆添加/删除权限
            id:'',                      //公司ID
            introduction:'',            //简介
            isbn:'',                    //国际编码
            issued:'',                  //核发机关
            jurdicalAddress:'',         //法人地址
            jurdicalPerson:'',          //法人
            jurdicalPhone:'',           //法人联系电话
            liaisonEmail:'',            //联系邮箱
            liaisonPerson:'',           //联系人
            liaisonPhone:'',            //联系电话
            loadLicence:'',             //道路运输许可证
            masterAccount:'',           //主账户
            minitorLeader:'',           //监控负责人
            minitorLeaderPhone:'',      //监控负责人联系方式
            minitorPerson:'',           //监控员
            operator:'',                //运营商
            parentBusiness:'',          //上级公司
            registerFund:'',            //注册资金
            remark:'',                  //备注
            secret:'',                  //密码
            trade:'',                   //所属行业
            validity:'',                //有效期
            websiteLink:'',             //网站链接
        },
        paswod:'',
        // 字体控制
        fontType: '中文',
    },
    mutations: {
        //名字不能和actions里重复
        // 改变title的值
        title2(state, ti) {
            state.title = ti;
        },
        // 实时监控目标
        RTMOT(state, item) {
            state.monitorTarget = item
        },
        // 车辆
        goDetailsData(state, item) {
            state.detailsData = item
        },
        // 司机
        modifyDriverData1(state, item) {
            state.seeDriverData = item
        },
        // 公司
        modifyCompanyData1(state, item) {
            state.seeCompanyData = item
        },
    },
    modules: {
        tab,
		permission
    },
	getters,
    actions: {
        // 改变title的值
        title(context, ti) {
            context.commit('title2', ti);
        },
        // 实时监控目标
        RTMOT1(context, item) {
            context.commit('RTMOT', item);
        },
        // 车辆
        detailsData(context, item) {
            context.commit('goDetailsData', item);
        },
        // 司机
        modifyDriverData(context, item) {
            context.commit('modifyDriverData1', item);
        },
        // 公司
        modifyCompanyData(context, item) {
            context.commit('modifyCompanyData1', item);
        },
    },
})
