
import { createI18n } from "vue-i18n"
import { getCurrentInstance} from 'vue'

import zh from "./zh"
import hk from "./hk"
 
 
// 默认语言 - 
const default_lang = localStorage.getItem('lang')
 
const i18n = createI18n({
    // legacy: false,
    locale:default_lang,
    messages:{
      zh,
      hk
    }
    
})
 
export default i18n //对外暴露 i18n, 在 main.js 中挂载