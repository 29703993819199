import {handleRoutes,handleTab} from '@/utils/routerUtils'
import {constantRoutes} from "@/router/index"
import router from "@/router/index"
import store from "@/store";

const state = {
    routes: [],
    addRoutes: [],
    menuRouters: [],
	loading: false
}

const mutations = {
    SET_ROUTES: (state, routes) => {
		state.menuRouters = []
		state.addRoutes = []
		state.routes = []
		
        state.menuRouters = routes
        state.addRoutes = routes
        state.routes = constantRoutes.concat(routes)
    },
	LOADING_ROUTES: (state,routes) => {
		// 设置routes
		let _routes = JSON.stringify(routes)
		localStorage.setItem("routes",_routes)
		let router1 = handleRoutes(_routes)
		
		let layoutRouter = {
		    path: '/home',
		    component: () => import('@/views/Home'),
		    children: router1,
		}
		
		router.addRoute(layoutRouter)
		router.options.routes = router1
		router.isReady().then(() => {
			console.log('Routes assembled successfully');
		})
	}
}

const actions = {
   generateRoutes({ commit }) {
        return new Promise(resolve => {
			let routerData = localStorage.getItem("routes")
			if(routerData == null || routerData == undefined || routerData == "") {
				router.push('/login')
				return false
			}
			
			let router1 = handleRoutes(routerData)
			let layoutRouter = {
			    path: '/home',
			    component: () => import('@/views/Home'),
			    children: router1,
			}
			
			router1.push({ path: '*', redirect: '/404',type:'error', hidden: true })
			commit('SET_ROUTES', router1);
			// 设置Tab菜单信息
			store.commit('SET_MENU',routerData)
			resolve(layoutRouter);
        })
    },
	setRoutes({ commit },routes) {
		
	}
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}