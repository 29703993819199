import Vuex from 'vuex'

export default new Vuex.Store({
    state: {
		debug: true,				 // 是否调试
		use: true,					 // 是否使用(如不使用则设置为:false)
        websocket: null,			 // socket
        socketOpen: false, 			 // 开启标识
        hearbeatTimer: null, 		 // 心跳timer
        hearbeatInterval: 10 * 1000, // 心跳发送频率
        isReonnect: true, 			 // 是否自动重连
        reconnectCount: 5, 			 // 重连次数
        reconnectCurrent: 1, 		 // 已发起重连次数
        reconnectTimer: null, 		 // 重连timer
        reconnectInterval: 6 * 1000, // 重连频率
		// 消息类型							 
		action: {
			AC0000: 0,// 系统（备用）
			AC0001:	1,// 第一次(或重连)初始化连接
			AC0002:	2,// 心跳消息
			AC0003:	3,// 实时轨迹
			AC0004:	4,// 电召信息
			AC0005:	5,// 报警信息
			AC0006:	6,// 终端指令日志上下行
		}
    },
    geters: {
       onEvent(state) {
           return function (method) {
               let index = state.eventlist.map((eb) => {return eb.method}).indexOf(method);
               if (state.eventlist.length > 0 && index >= 0) {
                   let result = Object.assign({}, state.eventlist[index]);
                   state.eventlist.splice(index, 1);
                    return result.data;
                }
                return null;
            }
        },
		// 首次与服务端建立连接
		onFirstMsg(state,token) {
			return function () {
				let data = {
				    action: state.action.AC0001,
					token: token
				}
				
				return JSON.stringify(data)
			}
		}
    },
    mutations: {
		initWebSocket(state,url) {
			var ths = this
			url = `ws://127.0.0.1:8902/ws`
			// url = `ws://47.112.224.58:8902/ws`
			var token = localStorage.getItem('token')
			if(token == null||token==undefined||token == "null") {
				return false
			}
			
			state.websocket = new WebSocket(url)
			state.websocket.onopen = function () {
				if(state.debug) {
					console.log("WebSocket连接成功!!!" + new Date() + "["+state.websocket.readyState+"]");
					// console.log("WebSocket连接成功!!!" + new Date() + "["+state.websocket.readyState+"]");
				}
				
				// 首次连接的消息，进行用户编号和通道绑定！
				let data = {
					action: state.action.AC0001,
					data: token
				}
				ths.commit('send',data)
				
				state.socketOpen = true
				state.isReonnect = true
				// 开启心跳
				ths.commit('heartbeat')
			}
			
			state.websocket.onmessage = function (event) {
				if(state.debug) {
					console.log(">>>WS OnMessage !");
					console.log(event.data)	
				}
				// console.log(">>>WS OnMessage !");
				// console.log(event.data)

				// 重置心跳机制，如果是服务端一直推送消息过来，则不需要进行心跳检测！ 
				ths.commit('resetHeartbeat')
				
				// 自定义全局监听事件
				window.dispatchEvent(new CustomEvent('onmessageWS', {
					detail: {
						data: event.data
					}
				}))
			}
			
			state.websocket.onclose=function(e) {
				if(state.debug) {
					console.log(">>>WS OnClose !");
					console.log(e)
				}
				
				// 清除心跳机制
				clearTimeout(state.hearbeatTimer)
				state.socketOpen = false
				// 需要重新连接
				if (state.isReonnect) {
				    state.reconnectTimer = setTimeout(() => {
				        // 超过重连次数(已发起重连次数 > 重连次数)
				        if (state.reconnectCurrent > state.reconnectCount) {
							// 清除重试链接机制
				            clearTimeout(state.reconnectTimer)
						
				            state.isReonnect = false
							if(state.debug) {
								console.log('重连连接服务，重连'+state.reconnectCount+'次，放弃重连！')
							}
				            return
				        }
						
				        // 记录重连次数
				        state.reconnectCurrent++
						ths.commit('reconnect')
				    }, state.reconnectInterval)
				}
			}
			
			state.websocket.onerror=function(e) {
				console.log(">>>WS OnError !");
				if(state.debug) {
					console.log(e);
				}
				
			}
		},
		reconnect(state) {
			var ths = this
			if (state.websocket && !state.isReonnect) {
				state.websocket.close()
			}
			ths.commit('initWebSocket')
		},
		heartbeat(state) {
			var ths = this
			state.hearbeatTimer && clearInterval(state.hearbeatTimer)
			state.hearbeatTimer = setInterval(() => {
				
				// 发送心跳数据
				let data = {
					action: state.action.AC0002,
				}
			    ths.commit('send',data)
			}, state.hearbeatInterval)
		},
		resetHeartbeat(state) {
			var ths = this
			// 清除重试链接机制
			clearTimeout(state.reconnectTimer)
			// 清除心跳机制
			clearTimeout(state.hearbeatTimer)
			// 开启心跳
			ths.commit('heartbeat')
		},
		send(state,data){
			 if (state.websocket.readyState === state.websocket.OPEN) {
				 let msg = sendMessageAssembly(data)
			     state.websocket.send(msg)
			 } else {
			     clearInterval(state.hearbeatTimer)
			    //  console.log('socket链接已断开')
			 }
		},
		close(state) {
			state.isReonnect = false
			state.websocket.close()
			state.websocket = null
			// console.log('WEBSOCKET_CLOSE')
		}
    },
    actions: {
        WEBSOCKET_INIT({commit},url) {
			commit('initWebSocket',url)
        },
        WEBSOCKET_SEND({commit}, data) {
			commit('send', data)
        },
		WEBSOCKET_CLOSE({commit}) {
			commit('close')
		}
    }
})


/**
 * 发送消息
 * @param {Object} state
 * @param {Object} event
 */
function sendMessageAssembly(event) {
	let msg = null
	let {action,data} = event
	switch(action) {
		case 1: 
		msg = firstMsg(action,data)
		break
		case 2:
		msg = heartbeatMsg(action)
		break
		case 3:
		msg = realTimeLocusMsg(action,data)
		break
		case 4:
		break
		case 6:
		msg = sendingCarNum(action,data)
		break
	}
	console.log(msg)
	return msg
}

/**
 * 心跳消息
 */
function heartbeatMsg(action) {
	let msg = {
		action: action
	}
	return JSON.stringify(msg)
}

/**
 * 首次连接
 */
function firstMsg(action,token) {
	let msg = {
		action: action,
		data: {token:token}
	}
	return JSON.stringify(msg)
}

/**
 * 实时轨迹
 */
function realTimeLocusMsg(action,data) {
	let msg = {
		action: action,
		data: data
	}
	
	return JSON.stringify(msg)
}

/**
 * 终端指令日志上下行
 */
function sendingCarNum(action,data) {
	let msg = {
		action: action,
		data: data
	}
	return JSON.stringify(msg)
}


