import { resolveComponent as _resolveComponent, createVNode as _createVNode, KeepAlive as _KeepAlive, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  const _component_el_config_provider = _resolveComponent("el-config-provider");

  return _openBlock(), _createBlock(_component_el_config_provider, {
    locale: $setup.locale
  }, {
    default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, null, [_createVNode(_component_router_view)], 1024))]),
    _: 1
  }, 8, ["locale"]);
}