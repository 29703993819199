export default {
  name: "anths",
  props: {
    authItem: {
      type: [String, Array],
      // 类型[字符串、数组]
      required: true // 必要性

    }
  },

  data() {
    return {
      isShow: false
    };
  },

  created() {
    const authArr = this.$router.currentRoute.value.meta.permissions;
    const authItem = this.authItem;

    if (Object.prototype.toString.call(this.authItem) === '[object Array]') {
      if (this.includes(authArr, authItem)) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    } else {
      this.isShow = authArr.includes(authItem);
    }
  },

  methods: {
    includes(permissions, auths) {
      let isAuth = false; // 判断不是数组进行处理

      if (Object.prototype.toString.call(permissions) != '[object Array]') {
        permissions = permissions.split(',');
      }

      if (Object.prototype.toString.call(auths) != '[object Array]') {
        auths = auths.split(',');
      }

      for (let i = 0; i < auths.length; i++) {
        let boot = permissions.includes(auths[i]);
        if (!boot) continue;
        isAuth = boot;
      }

      return isAuth;
    }

  }
};