const zh = {
    // 左侧菜单
    messages:{
        SSJK:'实时监控',
        AQJK:'安全监控',
        LSHF:'历史回放',
        AQGL:'安全管理',
        TJBB:'统计报表',
        YYGL:'运营管理',
        GZGL:'规则管理',
        ZDAQBJCL:'主动安全报警处理',
        TJFXBB:'统计分析报表',
        CLXX:'车辆信息',
        SJXX:'司机信息',
        CDGL:'车队管理',
        GZWH:'规则维护',
    },
    //历史回放
    lshf:{
        FJ:'附近',
        KSRQ:'开始日期',
        JSRQ:'结束日期',
        SS:'搜索',
        CPH:'选择/输入车牌',
        ACLXZ:'按车辆选择',
        QYGL:'区域管理',
        SDXZ:'手动选择',
        WLGL:'围栏管理',
        XZFW:'选择范围',
        QRSS:'确认搜索'
    },
    // 车辆信息页面
    CLXXpage:{
        SSCD:'所属车队',
        XZZT:"选择状态",
        XZGS:"选择公司",
        AZRQXZ:"安装日期选择:",
        AZKSRQ:"安装开始日期",
        AZJSRQ:"安装结束日期",
        PPLX:"匹配类型",
        QSRMHCXNR:"请输入模糊查询内容",
        SS:"搜索",
        SYCL:"所有车辆",
        TJCL:"添加车辆",
        XGGS:"修改公司",
        CEDR:"从Excel导入",
        ZDYDC:"自定义导出",
        CLid:"车辆ID",
        CPH:"车牌号",
        SIMK:"SIM卡",
        CLXH:"车辆型号",
        CPYS:"车牌颜色",
        SBH:"设备号",
        ZHSXSJ:"最后上线时间",
        SSGS:"所属公司",
        CD:"车队",
        JSYXM:"驾驶员姓名",
        GXSJ:"更新时间",
        CLXX:"车辆信息",
        BJCL:"编辑车辆",
        YHSQ:"用户授权",
        SCCL:"删除车辆",
        JBXX:"基本信息",
        SBXX:"设备信息",
        SBAZXX:"设备安装信息",
        ZJXX:"证件信息",
        CLPZCS:"车辆配置及主要技术参数",
        JYYS:"经营运输信息",
        GMDJ:"购买登记信息",
        QRXG:'确认修改',
        CKCLXX:'查看 车辆信息',
        GB:'关闭',
        BJCLXX:'编辑  车辆信息',
        HYSQ:'用户授权',
        SQ:'授权',
        SCCLXX:'删除车辆信息',
        S:'是',
        F:'否',
        QX:'取消',
        TJCLXX:'添加 车辆信息',
        QDTJ:'确定添加',
        DCBG:'导出表格',
        BC:'保存',
        CLYS:'车辆颜色',
        DLS:'代理商',
        QYBH:'企业编号',
        JC:'简称',
        FWDQR:'服务到期日',
        CLZL:'车辆种类',
        CLLX:'车辆类型',
        ZZCJ:'车辆厂牌号',
        CLYT:'车辆用途',
        BFRQ:'报废年限',
        XSZ:'限速值',
        LCXS:'里程系数',
        CJH:'车架号',
        SJ:'司机',
        YYS:'运营商',
        FWKSR:'服务开始日',
        AZRQ:'安装日期',
        CLPP:'车辆品牌',
        CLDJ:'车辆等级',
        SYZT:'营运状态',
        NJRQ:'年检日期',
        BLTS:'保留天数',
        CPHDL:'车牌号登录',
        QY:'启用',
        JSMC:'角色名称（分配权限）',
        CLTU:'车辆图标',
        BZ:'备注',
        SB:'设备',
        SIMKH:'SIM卡号',
        ZDLX:'终端类型',
        SBXLH:'设备序列号',
        SIMKHSJ:'SIM卡号数据',
        SJKH:'计价器号',
        TDCS:'通道参数',
        IOSRCS:'IO输入参数',
        CGQCS:'传感器参数',
        SM:'数目',
        WSMK:'外设模块',
        ZCSP:'支持视频',
        ZCDJ:'支持对讲',
        ZCJT:'支持监听',
        TTSYYBF:'TTS（TTS语音播放）',
        ZYX:'主油箱',
        FYX:'副油箱',
        YLKZQ:'油路控制器（断开油路和恢复油路）',
        DLKZQ:'电路控制器（断开电路和恢复电路）',
        KLTJ:'客流统计',
        TYJCXT:'胎压监测系统',
        CZCJJQ:'出租车计价器',
        ZLCGQ:'重量传感器',
        SWCGQ:'水位传感器',
        SSCGQ:'水深传感器',
        DZS:'电子锁',
        AZQK:'安装情况',
        TYJC:'胎压监测',
        GJJSFZ:'高级驾驶辅助',
        JSZTKZ:'驾驶状态监控',
        MDJC:'盲点监测',
        BDJCFZ:'变道决策辅助',
        YTJ:'一体机',
        TYJCPP:'胎压监测品牌',
        TYJCXH:'胎压监测型号',
        GJJSFZPP:'高级驾驶辅助品牌',
        GJJSFZXH:'高级驾驶辅助型号',
        JSZTJKPP:'驾驶状态监控品牌',
        JSZTJKXH:'驾驶状态监控型号',
        MDJCPP:'盲点监测品牌',
        MDJCXH:'盲点监测型号',
        BDJCFZPP:'变道决策辅助品牌',
        BDJCFZXH:'变道决策辅助型号',
        YTJPP:'一体机品牌',
        YTJXH:'一体机型号',
        SBAZZP:'设备安装照片',
        SCXZTP:'上传选择图片',
        AZR:'安装人',
        AZRDH:'安装人电话',
        XSZBH:'行驶证编号',
        YSZBH:'运输证编号',
        XSZDQSJ:'行驶证到期时间',
        YSZDQSJ:'运输证到期时间',
        XSZZP:'行驶证照片',
        XZSCTP:'选择上传图片',
        DLYSZGZ:'道路运输资格证',
        CLT:'车辆图',
        CLDDZZP:'车辆登记证照片',
        ZFZZP:'身份证照片',
        DPXH:'底盘型号',
        WKCCC:'外廓尺寸长',
        WGCCK:'外廓尺寸宽',
        WKCCG:'外廓尺寸高',
        NKCCC:'内廓尺寸长',
        NKCCK:'内廓尺寸宽',
        NKCCG:'内廓尺寸高',
        ZZL:'总质量',
        ZBZL:'整备质量',
        ZQYZZL:'准牵引总质量',
        HDZZ:'核定载重(吨)',
        HDZRS:'核定载人数',
        FDJXH:'发动机型号',
        FDJH:'发动机号',
        PL:'排量',
        GL:'功率',
        PFBZ:'排放标准',
        RLZL:'燃料种类',
        DCLX:'电池类型',
        QDDJXH:'驱动电机型号',
        DJGL:'电机功率',
        DLLX:'动力类型',
        ZJ:'轴距',
        ZS:'轴数',
        LTS:'轮胎数/规格',
        XCZDFS:'行车制动方式',
        ZDQXS:'制动器形式',
        ZDFBSXT:'制动防抱死系统(ABS)',
        BSQXS:'变速器形式',
        HSQ:'缓速器',
        KTXT:'空调系统',
        YYYSXX:'经营运输信息',
        YYZH:'营运证号',
        BXLB:'企业经营许可证',
        XLLC:'线路里程',
        YYXL:'运营线路',
        DLYSXKZ:'道路运输许可证',
        JYQX:'经营期限到期时间',
        JYFW:'经营范围',
        JYXZ:'经营性质',
        DY:'地域',
        HFJG:'核发机关',
        YSHYLB:'运输行业类别',
        YSCLX:'运输车辆类型',
        CJD:'车籍地',
        HWDS:'货物吨数/始发地',
        HWPM:'货物品名/讫发地',
        YSCFD:'运输出发地/始发站',
        YSMDD:'运输目的地',
        HWTJ:'货物体积(立方)',
        GMDJXX:'购买登记信息',
        BH:'编号',
        JDCSYR:'机动车所有人',
        DJRQ:'登记日期',
        DJZBH:'登记证编号',
        LXR:'联系人',
        LLDH:'联系电话',
        CLLY:'车辆来源',
        MBLC:'码表里程',
        ZRSJ:'转入时间',
        ZCSJ:'转出时间',
        CLGMRQ:'车辆购买日期',
        CLCCRQ:'车辆出厂日期',
        CJ:'车价',
        FPHM:'发票号码',
        GCFP:'购车发票',
        FR:'法人',
        FRDZ:'法人地址',
        FRLXDH:'法人联系电话',


        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',
        // CLYS:'',

    },
    //司机信息页面
    SjXXpage:{
        SYSJ: '所有司机', 
        TJSJ: '添加司机',
        YEXCDR: '由Excel导入', 
        DCZEXC: '导出至Excel',
        PLSC: '批量删除', 
        XZLX: '选择类型',
        MHCX: '模糊查询', 
        JSID: '司机ID',
        SJXM: '姓名', 
        SJXB: '性别',
        LXFS: '联系方式', 
        SFZHM: '身份证号码', 
        SSGS: '企业名称',
        JSZLX: '驾驶证类型', 
        JSZKSSJ: '驾驶证有效期开始时间',
        JSZJZSJ: '驾驶证有效期截止时间', 
        JSZH: '驾驶证号', 
        ZGZBH: '资格证编号',


        YZGZHM: '原资格证编号',
        ZT: '状态',
        ZGZT: '在岗状态',
        SJLX: '司机类型',
        SJXJ: '司机星级',
        GJ: '国籍',
        ZGZZXZT: '资格证注销状态',
        FWZGZHM:'服务资格证号码',

        SJXX: '司机信息', 
        BJSJ: '编辑司机',
        SCSJ: '删除司机', 
        JBXX: '基本信息',
        CPH: '车牌号',
        CSRQ: '出生日期', 
        JG: '籍贯', 
        MZ: '民族',
        DZ: '地址', 
        DY: '地域',
        WHCD: '文化程度', 
        AQJY: '安全教育',
        YWC: '已完成',
        WWC: '未完成', 
        SFZZP: '身份证照片',
        SJRX: '司机人像', 
        ZJZ: '证件照',
        ZWTP: '指纹图片', 
        ZJXX: '证件信息',
        QDTJ: '确定添加', 
        GB: '关闭',  
        JZSJ: '截止时间', 
        KSJS:'开始时间', 
        ZGZZT: '资格证状态', 
        ZGZLX: '资格证类别',
        ZGZTP: '资格证图片', 
        DLYSZGZ: '道路运输资格证', 
        DLYSZGZZP: '道路运输资格证照片',
        ZSZZP: '驾驶证照片', 
        JZLX: '驾驶证类别',
        JSZBZRQ: '驾驶证办证日期', 
        JSZYXQKSSJ: '驾驶证有效期开始时间', 
        JSZYXQJZSJ: '驾驶证有效期截止时间',
        TQTXTS: '提前提醒天数', 
        YYZZP: '营运证照片', 
        CLXX: '车辆信息',
        JDKH: '监督卡号', 
        JDKYXQ: '监督卡有效期', 
        YXQ: '有效期',
        JDKZT: '监督卡状态', 
        HFJG: '核发机关', 
        WYCJSZH: '网约车驾驶证号',
        WYCJSZTP: '网约车驾驶证图片', 
        WYCKSSJ: '网约车驾驶证有效期开始时间', 
        WYCJSSJ: '网约车驾驶证有效期结束时间',
        YXQKSSJ: '有效期开始时间', 
        YXQJSSJ: '有效期结束时间', 
        SKXX: '收款信息', 
        SJYHKH: '司机银行卡号', 
        ZFBSKM: '支付宝收款码',
        WXSKM: '微信收款码', 
        YZFEWM: '翼支付二维码', 
        QR: '确认', 
        QRBJ: '确认编辑',
        SFSCSJ: '是否将该司机信息删除？', 
        S: '是', 
        F: '否',
        QX: '取消',
        CKSJXX:'查看司机信息',
        SCSJXX:'删除司机信息',
        BJSJXX:'编辑司机信息',
        TJSJXX:'添加司机信息'
    },
    // 公司页面信息
    company:{
        QYJC:'企业简称',
        QYJYZT:'企业经营状态',
        JJLX:'经济类型',
        JYXKZ:'经营许可证',
        JYFW:'经营范围',

        SYGS:'所有公司',
        XGGS:'修改查岗',
        TJGS:'添加公司',
        DCZEXC:'导出至Excel',
        CEXCDR:'从Excel导入',
        GSID:'公司ID',
        GSMC:'公司名称',
        SJXZQY:'上级行政区域',
        SSHY:'所属行业',
        PPLX:'匹配类型',
        MHSS:'模糊搜索',
        ZZH:'主账号',
        LXR:'联系人',
        LXDH:'联系电话',
        DY:'地域',
        HFJG:'核发机关',
        FRLXDH:'法人联系电话',
        FRDZ:'法人地址',
        GSXQ:'公司详情',
        BJGS:'编辑公司',
        BTSZ:'标题设置',
        AAAA:'删除公司',
        QRBJ: '确认编辑',
        QR: '确认',
        S: '是', 
        F: '否',
        QX: '取消',
        SFSCGS: '是否将该公司信息删除？',
        CKGSWXX:'查看公司信息',
        SCGSWXX:'删除公司信息',
        BJGSWXX:'编辑公司信息',
        TJGSWXX:'添加公司信息',
        JBXX:'基本信息',
        YXQ:'有效期',
        DLMM:'登录密码',
        QDMM:'确定密码',
        DLYSXJZ:'道路运输许可证	',
        SJGS:'上级公司',
        FR:'法人',
        LXYX:'联系邮箱',
        JKY:'监控员',
        JKFZR:'监控负责人',
        YYS:'运营商',
        JKFZRLXFS:'负责人联系方式',
        CLTJ:'车辆添加/删除权限',
        CGHFZH:'查岗回复账号',
        GBBM:'企业编号',
        CGZDHF:'查岗自动回复',
        QY:'启用',
        XTXX:'选填信息',
        YYZZH:'营业执照号	',
        ZCJZ:'注册资金',
        DZ:'地址',
        JJ:'简介（公司营业范围）',
        WZLJ:'网站链接',
        BZ:'备注',
        GB:'关闭',
        QDTJ: '确定添加', 
        NRBNWK:'内容不能为空',
        MR:'默认',
        CDU:'长度为4-16位数',
        MMBYZ:'密码不一致',

    }
}
export default zh