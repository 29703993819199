import { createApp } from 'vue';
import App from './App.vue';
import './common/base.css';

import * as elementIcons from '@element-plus/icons-vue';
import ElementPlus from 'element-plus';
import router from './router/index';
import store from './store';
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
import 'element-plus/dist/index.css';
import i18n from './lang'
import VueContextMenu from 'vue-contextmenu'
import * as echarts from "echarts";

// webSocket
import socket from './store/webSocket'
import Anths from './components/anths/anths'

import {hasPermission} from './utils/permission'

// app.provide('$axios', axios)
const app = createApp(App);
for (let iconName in elementIcons) {
  app.component(iconName, elementIcons[iconName]);
}

// 将自定义组件注册成全局组件
app.component(Anths.name, Anths)

// 挂载到原型上
app.config.globalProperties.$socket = socket;
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$hasPermission = hasPermission;

 app.use(router).use(store).use(ElementPlus).use(i18n).use(VueVideoPlayer).use(VueContextMenu).mount('#app');
