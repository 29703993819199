import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store/index'; // 固定路由信息

export const constantRoutes = [{
  path: '/',
  redirect: '/login'
}, {
  path: '/login',
  component: () => import('../views/Login')
}, {
  path: '/register',
  component: () => import('../views/Register')
}]; // 路由配置

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
}); //路由拦截

router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/404') {
    next();
  } else {
    var token = localStorage.getItem('token');

    if (token && token != '' && token != null) {
      if (store.getters.addRoutes.length == 0) {
        store.dispatch('permission/generateRoutes').then(accessRoutes => {
          let asyncRouter = accessRoutes; // 根据后端请求的数据生成可访问的路由表
          // 动态添加可访问路由表

          router.addRoute(accessRoutes);
          router.options.routes = store.getters.routes;
          router.isReady().then(() => {
            console.log('Routes assembled successfully');
          });
          next({ ...to,
            replace: true
          }); // hack方法 确保addRoutes已完成
        });
      } else {
        next();
      }
    } else {
      ElMessage.error('请先登录');
      next('/login');
    }
  }
});
export default router;