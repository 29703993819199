import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
// 加入中文模块
import { getCurrentInstance, nextTick } from 'vue';
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import router from './router/index';
import { useStore } from 'vuex';
let store;
let font = '18px';
const __default__ = {
  name: 'App',
  components: {
    ElConfigProvider
  },

  setup() {
    return {
      locale: zhCn,
      timer: null,
      _proxy: null
    };
  },

  mounted() {
    store = useStore(); // 当任意路由也进行刷新，WebSocket重新建立链接

    const {
      proxy
    } = getCurrentInstance();

    if (proxy.$socket.state.use) {
      proxy.$socket.dispatch('WEBSOCKET_INIT');
    }

    this._proxy = proxy; // 添加socket通知监听

    window.addEventListener('onmessageWS', this.getSocketData); // 服务到期温馨提示
    //this.serviceExpirationTip()
  },

  methods: {
    // 收到消息处理
    getSocketData(res) {
      var data = eval('(' + res.detail.data + ')');

      if (data == null || data == undefined) {
        return false;
      }

      if (data.action == 0) {
        this.handleSysTip(data);
      }

      if (data.action != 4) {
        return false;
      }

      router.push('/phoneOrder');
      store.state.homeClass = 6;
      nextTick(() => {
        store.state.passengerPhone = JSON.parse(JSON.stringify(data.data));
      });
      ElMessage({
        message: '电召消息提示',
        type: 'success'
      });
    },

    handleSysTip(data) {
      let {
        msg,
        type
      } = data['data'];

      switch (type) {
        case 304:
          this.logout();
          break;

        default:
      }
    },

    logout() {
      // 用户权限发生改变，即将退出！
      ElMessage({
        message: '用户权限发生改变，即将退出！',
        type: 'warning'
      });
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.timer = null;
        this.removeLocalStorage();
      }, 4000);
    },

    removeLocalStorage() {
      localStorage.setItem("token", null);
      localStorage.setItem("username", null);
      localStorage.removeItem("mapPlacebaiduX", null);
      localStorage.removeItem("mapPlacebaiduY", null);
      localStorage.removeItem("mapPlacescale", null);
      localStorage.removeItem("routes"); // 退出登录，关闭ws连接

      this._proxy.$socket.dispatch('WEBSOCKET_CLOSE');

      router.push('/login');
    } // // 車服務到期提醒
    // serviceExpirationTip() {
    //   ElNotification({
    //     title: '服务到期提醒',
    // 	type: 'warning',
    // 	duration: 0,
    // 	position: 'bottom-right',
    // 	dangerouslyUseHTMLString: true,
    //     message: '<strong>This is <i>HTML</i> string</strong>',
    //   })
    // }


  }
};
import { useCssVars as _useCssVars } from 'vue';

const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "97609876": _ctx.$store.state.font
  }));
};

const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();

  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;