// import { useRouter } from 'vue-router';
// const routerTemplate = useRouter();
import {handleTab} from '@/utils/routerUtils'
const tab = {
  state() {
    return {
      isCollapse: false,
      currentMenu: null,
	  menu:[],
      menu1: [
        {
          path: "/monitor",
          label: "实时监控",
          name: "monitor",
          // icon: "s-home",
          children: [
            {
              path: "/monitor",
              name: "monitor",
              label: "实时监控",
              icon: "setting",
            },
          ],
        },
        {
          label: "历史回放",
          icon: "s-home",
          children: [
           
            {path: "/history",name: "history",label: "历史回放",icon: "setting",},
            {path: "/regioncar",name: "regioncar",label: "区域查车",icon: "setting",}
          ]
        },
        {
          label: "统计报表",
          icon: "s-home",
          children: [
            {
              label: "出租车营运报表",
              icon: "s-home",
              children:[
                {path: "/dayCountTable",name: "dayCountTable",label: "日运营统计表",icon: "setting"},
                {path: "/monthCountTable",name: "monthCountTable",label: "月运营统计表",icon: "setting"},
                {path: "/tradeIncomeSummary",name: "tradeIncomeSummary",label: "营运收入总汇表",icon: "setting",},
                {path: "/incomeDetailed",name: "incomeDetailed",label: "营运收入明细表",icon: "setting"},
                {path: "/callingUpDetailed",name: "callingUpDetailed",label: "电召明细表",icon: "setting"},
                {path: "/callingUpService",name: "callingUpService",label: "电召业务量",icon: "setting"},
                {path: "/onlineDataCount",name: "onlineDataCount",label: "在线天数统计",icon: "setting"},
              ]
            },
            {
              label:'故障报警报表',
              icon: "s-home",
              children:[
                {path: "/queryFault",name: "queryFault",label: "疑似故障车辆",icon: "setting"},
                // {path: "/alarmQuery",name: "alarmQuery",label: "报警查询",icon: "setting"},
              ]
            },
            {
              label:'报警报表',
              icon: "s-home",
              children:[
                {path: "/alarmReport",name: "alarmReport",label: "报警统计",icon: "setting"},
                {path: "/alarmQuery",name: "alarmQuery",label: "报警查询",icon: "setting"},
              ]
            },
            {
              label:'行车报表',
              icon: "s-home",
              children:[
                {path: "/trainWorkingCount",name: "trainWorkingCount",label: "行车统计查询",icon: "setting"},
                {path: "/travelTimeDetail",name: "travelTimeDetail",label: "行驶时间明细",icon: "setting"},
                {path: "/suspendedTimeDetail",name: "suspendedTimeDetail",label: "停驶时间明细",icon: "setting"},
                {path: "/travelMileageCount",name: "travelMileageCount",label: "行驶里程统计",icon: "setting"},
                {path: "/MileageMonthForms",name: "MileageMonthForms",label: "里程月报表",icon: "setting"},
                {path: "/travelAnalysisForms",name: "travelAnalysisForms",label: "行驶分析报表",icon: "setting"},
                {path: "/accFiringDetailQuery",name: "accFiringDetailQuery",label: "ACC点火明细查询",icon: "setting"},
                {path: "/accFiringCount",name: "accFiringCount",label: "ACC点火统计",icon: "setting"},
              ]
            },
            {
              label:'电子围栏报表',
              icon: "s-home",
              children:[
                {path: "/fenceFence",name: "fenceFence",label: "电子围栏统计",icon: "setting"},
                {path: "/fenceQuery",name: "fenceQuery",label: "电子围栏查询",icon: "setting"},
                // {path: "/accessRegionDetailed",name: "accessRegionDetailed",label: "进出区域明细表",icon: "setting"},
                // {path: "/illegalUpGuestAlarm",name: "illegalUpGuestAlarm",label: "非法上客报警",icon: "setting"},
                // {path: "/illegalDownGuestAlarm",name: "illegalDownGuestAlarm",label: "非法下客报警",icon: "setting"},
              ]
            },
            {
              label:'司机报表',
              icon: "s-home",
              children:[
                {path: "/driverClockInSummary",name: "driverClockInSummary",label: "司机打卡汇总表",icon: "setting"},
                {path: "/driverClockInDetailed",name: "driverClockInDetailed",label: "司机打卡明细表",icon: "setting"},
              ]
            },

          ],
        },
        {
          label: "运营管理",
          icon: "s-home",
          children: [
            {
              path: "/cartInformation",
              name: "cartInformation",
              label: "车辆信息",
              icon: "setting",
            },
            {
              path: "/driverInformation",
              name: "driverInformation",
              label: "司机信息",
              icon: "setting",
            },
            {
              path: "/fleetManagement",
              name: "fleetManagement",
              label: "车队管理",
              icon: "setting",
            },
            {
              path: "/companyinformation",
              name: "companyinformation",
              label: "公司信息",
              icon: "setting",
            },
            {
              path: "/userManagement",
              name: "userManagement",
              label: "用户管理",
              icon: "setting",
            },
            {
              path: "/roleManage",
              name: "roleManage",
              label: "角色管理",
              icon: "setting",
            },
            {
              path: "/regionManage",
              name: "regionManage",
              label: "区域管理",
              icon: "setting",
            }
          ],
        },
        {
          label: "规则管理",
          icon: "s-home",
          children: [
            {
              label: "规则维护",
              icon: "setting",
              children:[
                {
                  'label': "区域路线",
                  children:[
                    {'label': "禁入规则", path: "/debarRule",name: "debarRule",icon: "setting",},
                    {'label': "禁出规则", path: "/prohibitionRule",name: "prohibitionRule",icon: "setting"},
                    {'label': "非法上客", path: "/unlawfulUPGuest",name: "unlawfulUPGuest",icon: "setting"},
                    {'label': "非法下客", path: "/unlawfuLlowGuest",name: "unlawfuLlowGuest",icon: "setting"},
                    {'label': "限速规则", path: "/speedLimitRules",name: "speedLimitRules",icon: "setting"},
                    {'label': "聚集报警规则", path: "/gatherRule",name: "gatherRule",icon: "setting"},
                  ]
              },
              {
                'label': "定时任务",
                children:[
                  {'label': "定时拍照规则", path: "/cameraTimer",name: "cameraTimer",icon: "setting",},
                  {'label': "定时下发调度信息", path: "/cameraDispatchMSG",name: "cameraDispatchMSG",icon: "setting",},
                ]
              }
              ]
            },
          ],
        },
        {
          path: "/userFeedback",
          label: "用户反馈",
          name: "userFeedback",
          icon: "s-home",
          children: [
            {
              path: "/userFeedback",
              name: "userFeedback",
              label: "用户反馈",
              icon: "setting",
            },
          ],
        },
        {
          label: "电召管理",
          icon: "s-home",
          children: [
           
            {path: "/phoneOrder",name: "phoneOrder",label: "电召管理",icon: "setting",},
            {path: "/passengerInfo",name: "passengerInfo",label: "乘客信息",icon: "setting",}
          ]
        },
        {
          path: "/manyCarTrajectry",
          label: "多车轨迹",
          name: "userFeedback",
          icon: "s-home",
          children: [
            {
              path: "/manyCarTrajectry",
              name: "manyCarTrajectry",
              label: "多车轨迹",
              icon: "setting",
            },
          ],
        },
        {
          path: "/lookBoard",
          label: "实时看板",
          name: "userFeedback",
          icon: "s-home",
          children: [
            {
              path: "/lookBoard",
              name: "lookBoard",
              label: "实时看板",
              icon: "setting",
            },
          ],
        },
      ],
      // tabMenu: [],
      tabsList: [],
    };
  },
  mutations: {
    // setMenu(state, val) {
    //     state.tabMenu = val
    // },
    // clearMenu(state) {
    //     state.tabMenu = []

    // },
    addMenu(state, router) {
      let index = 0;

      if (state.tabsList.length === 0) {
        state.tabsList.push(router);
        //routerTemplate.push({ name: router.name });
      } else {
        state.tabsList.forEach((element) => {
          if (element.label == router.label) {
            index++;
          }
        });
        if (index == 0) {
          state.tabsList.push(router);
        }
      }
    },
    closeTab(state, val) {
      let result = state.tabsList.findIndex((item) => item.name === val.name);
      state.tabsList.splice(result, 1);
    },
    collapseMenu(state) {
      state.isCollapse = !state.isCollapse;
    },
	SET_MENU: (state, menu) => {
		let menuData = handleTab(menu)
		state.menu =[]
	    state.menu = menuData
	}
  },
  actions: {},
};
export default tab;
