//  return userPermissions.includes(permission)
import router from '@/router/index';
export const hasPermission = (permission) => {
	if (!permission) {
		return true
	}
	
	const auths = router.currentRoute.value.meta.permissions
	if(!auths || auths == undefined || auths == null) {
		return false
	}
	
	return auths.includes(permission)
}