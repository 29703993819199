import { defineAsyncComponent } from 'vue'
// 核心处理方法
function createRoute(route) {
    route.forEach((item) => {
        // 没有component直接结束
        if (item.type == 'M') {
           // 处理多级路由 , 有子路由递归的进行配置
           if (item.children && item.children.length) {
               createRoute(item.children)
           }
        } else if(item.type == 'C') {
			// 每一个路由懒加载配置
			item.component = loadAsyncComponent(item.component)
		}
    })
}


// 核心处理方法
let newData = []
function createRoute1(route) {
    route.forEach((item) => {
        // 没有component直接结束
        if (item.type == 'M') {
           // 处理多级路由 , 有子路由递归的进行配置
           if (item.children && item.children.length) {
               createRoute1(item.children)
           }
        } else if(item.type == 'C') {
			item.component = loadAsyncComponent(item.component)
			newData.push(item)
		}
    })
}

async function loadAsyncComponent(componentPath) {
  try {
    const component = await import(`@/MainViews${componentPath}.vue`);
    return component
  } catch (error) {
    // 处理组件加载失败的情况
    console.error(`Failed to load async component: ${componentPath}`, error);
    return null;
  }
}

/**
 * newData：存放数据的新数组
 *expanded：执行的方法
 *datas：要进行扁平化的数组
 */

const expanded = datas => {
	datas.forEach(e => {
		if (datas.children && datas.children.length > 0) {
			newData.push(e);
			expanded(e.children);
		}
	})
   
 };

// 封装路由配置处理函数
export function handleTab (data) {
	data =JSON.parse(data)
    createRoute(data)
    return data
}

// 封装路由配置处理函数
export function handleRoutes (data) {
	newData = []
	data = JSON.parse(data)
	createRoute1(data)
    return newData
}